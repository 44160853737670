import { createReducer } from "@reduxjs/toolkit";

import {
    removeReaction,
    submitReactionsEditModal,
} from "../../actions/reactions";
import { IReaction } from "../reactions";

export interface IReactionsPersistentState {
    reactions: IReaction[];
}

const getDefaultState = (): IReactionsPersistentState => {
    return {
        reactions: [],
    };
};

export const reactions = createReducer(getDefaultState(), (builder) => {
    builder.addCase(submitReactionsEditModal, (state, action) => {
        const existingIndex = state.reactions.findIndex(
            (r) => r.ui_id === action.payload.ui_id
        );
        if (existingIndex >= 0) {
            state.reactions[existingIndex] = action.payload;
        } else {
            state.reactions.push(action.payload);
        }
    });
    builder.addCase(removeReaction, (state, action) => {
        const existingIndex = state.reactions.findIndex(
            (r) => r.ui_id === action.payload.ui_id
        );
        if (existingIndex >= 0) {
            state.reactions.splice(existingIndex, 1);
        }
    });
});

import { createAction } from "@reduxjs/toolkit";
import { IAdvancedSearchData } from "../reducers/persistent/advancedsearch";

export const ADVANCED_SEARCH_ADD = "ADVANCED_SEARCH_ADD";
export const ADVANCED_SEARCH_UPDATE = "ADVANCED_SEARCH_UPDATE";
export const ADVANCED_SEARCH_REMOVE = "ADVANCED_SEARCH_REMOVE";
export const ADVANCED_SEARCH_CLEAR = "ADVANCED_SEARCH_CLEAR";

export const addAdvancedSearchItem =
    createAction<IAdvancedSearchData>(ADVANCED_SEARCH_ADD);

export const updateAdvancedSearchItem = createAction<IAdvancedSearchData>(
    ADVANCED_SEARCH_UPDATE
);

export const removeAdvancedSearchItem = createAction<string>(
    ADVANCED_SEARCH_REMOVE
);

export const clearAdvancedSearchData = createAction(ADVANCED_SEARCH_CLEAR);

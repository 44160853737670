import { createReducer } from "@reduxjs/toolkit";
import {
    ISavedStructure,
    clearStructures,
    saveStructure,
    setStructureDrawingTool,
    updateStructures,
} from "../../actions/saveStructure";

const MAX_SAVED_STRUCTURES: number = 10;

export interface ISavedStructuresState {
    [userId: number]: ISavedStructure[];
    tool?: string;
}

export interface IStructureDrawTool {
    key: string;
    name: string;
    show: boolean;
    default?: boolean;
    modalSize?: "sm" | "lg" | "xl";
    modalDialogClassName?: string;
}

export const getDefaultState = (): ISavedStructuresState => ({});

export const savedStructures = createReducer(getDefaultState(), (builder) => {
    builder.addCase(setStructureDrawingTool, (state, action) => {
        state.tool = action.payload;
    });
    builder.addCase(saveStructure, (state, action) => {
        const structure = action.payload.structure;

        if (state[action.payload.user_id] === undefined) {
            state[action.payload.user_id] = [];
        }

        const existingStructure = state[action.payload.user_id].find(
            (s) => s.smiles === structure.smiles
        );

        if (existingStructure) {
            existingStructure.name = structure.name;
            existingStructure.image = structure.image;
        } else {
            // Remove the oldest entry if we're over the max
            if (state[action.payload.user_id].length === MAX_SAVED_STRUCTURES) {
                state[action.payload.user_id].splice(0, 1);
            }

            state[action.payload.user_id].push(structure);
        }
    });
    builder.addCase(updateStructures, (state, action) => {
        for (const structure of action.payload.structures) {
            const existingStructure = state[action.payload.user_id]?.find(
                (s) => s.smiles === structure.smiles
            );
            if (existingStructure) {
                if (structure.name) {
                    existingStructure.name = structure.name;
                }
                if (structure.image) {
                    existingStructure.image = structure.image;
                }
            }
        }
    });
    builder.addCase(clearStructures, (state, action) => {
        return getDefaultState();
    });
});

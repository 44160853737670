import { createReducer } from "@reduxjs/toolkit";
import { submitAwsMarketplaceActivation } from "../actions/awsmarketplace";
import { createSubscription, createTrial } from "../actions/licenses";
import { getUserLicenseInfo, logIn, logOut } from "../actions/user";
import { IUserState } from "../types/IUserState";

const getDefaultState = (): IUserState => ({
    csrf_token: null,
});

export const user = createReducer(getDefaultState(), (builder) => {
    builder.addCase(logIn.success, (state, action) => {
        return getNewUser(action.payload);
    });
    builder.addCase(logOut.success, () => {
        return getNewUser();
    });
    builder.addCase(createTrial.success, (state, action) => {
        return mergeUser(state, action.payload.user);
    });
    builder.addCase(createSubscription.success, (state, action) => {
        return mergeUser(state, action.payload.user);
    });
    builder.addCase(submitAwsMarketplaceActivation.success, (state, action) => {
        if (action.payload.success) {
            return mergeUser(state, action.payload.user);
        }
    });
    builder.addCase(getUserLicenseInfo.success, (state, action) => {
        state.queries = action.payload.units_remaining;
    });
});

const getNewUser = (response = undefined): IUserState => {
    return response
        ? response
        : {
              csrf_token: "",
              queries: 0,
              socket_token: "",
              userName: "",
              isLicensedForEstimation: false,
          };
};

const mergeUser = (state, response): IUserState => {
    // copy all props except tokens
    const { csrf_token, socket_token, validation_token, ...restProps } =
        response;

    return {
        ...state,
        ...restProps,
    };
};

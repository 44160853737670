import { combineReducers } from "redux";

import { advancedsearch } from "./advancedsearch";
import { reactions } from "./reactions";
import { savedStructures } from "./savedStructures";
import { scoreWeights } from "./scoreWeights";

export const persistent = combineReducers({
    advancedsearch,
    saved_structures: savedStructures,
    reactions,
    scoreWeights,
});

import { createAction } from "@reduxjs/toolkit";

import { createRsaaAction } from "../../components/utilities/ReduxUtils";
import { IAwsMarketplaceActivation } from "../reducers/awsmarketplace";

export const SKIP_AWS_MARKETPLACE_ACTIVATION =
    "SKIP_AWS_MARKETPLACE_ACTIVATION";
export const SUBMIT_AWS_MARKETPLACE_ACTIVATION =
    "SKIP_AWS_MARKETPLACE_ACTIVATION";
export const AWS_MARKETPLACE_ACTIVATION = "AWS_MARKETPLACE_ACTIVATION";

export const skipAwsMarketplaceActivation = createAction(
    SKIP_AWS_MARKETPLACE_ACTIVATION
);

export const submitAwsMarketplaceActivation = createRsaaAction<
    number,
    IAwsMarketplaceActivation
>({
    path: "billing/aws-marketplace/activate",
    method: "POST",
    type: AWS_MARKETPLACE_ACTIVATION,
});

import { createReducer } from "@reduxjs/toolkit";
import { WritableDraft } from "immer";
import { DateTime } from "luxon";
import { IApiSelectOption } from "../../components/application/advancedsearch/AdvancedSearchElement";
import {
    clearOrganization,
    closeCreateAccountResult,
    createAccount,
    getFeatures,
    getLicenses,
    getOrganizationFeatures,
    getOrganizations,
    ICreateAccountResponse,
    saveOrganization,
    setOrganizationFeature,
    setOrganizationProperty,
} from "../actions/viridischemadmin";

export interface IOrganization {
    id: number;
    name?: string;
    license_id?: number;
    license_name?: string;
    start_date?: number;
    end_date?: number;
    seats?: number;
    max_queries?: number;
    max_session_queries?: number;
}

export interface ILicense {
    id: number;
    name: string;
    display_name: string;
    description: string;
    max_seats: number;
    features: string[];
}

export interface IOrganizationReduxState {
    organization?: IOrganization;
    features?: string[];
}

export interface IOrganizationsReduxState {
    organizations: IOrganization[];
    organizations_data: { [key: number]: IOrganizationReduxState };
    pages: number;
}

export interface IViridisChemAdminReduxState {
    organizations: IOrganizationsReduxState;
    features: IApiSelectOption[];
    licenses: ILicense[];
    saved_timestamp: number;
    createAccountResult?: ICreateAccountResponse;
}

const getDefaultState = (): IViridisChemAdminReduxState => {
    return {
        organizations: {
            organizations: [],
            organizations_data: {},
            pages: 1,
        },
        features: [],
        licenses: [],
        saved_timestamp: 0,
    };
};

const createIfNotExists = (
    state: WritableDraft<IViridisChemAdminReduxState>,
    organizationId: number
) => {
    if (!state.organizations.organizations_data[organizationId]) {
        state.organizations.organizations_data[organizationId] = {};
    }

    const orgState = state.organizations.organizations_data[organizationId];

    if (!orgState.organization) {
        orgState.organization = {
            id: organizationId,
        };
    }

    return orgState;
};

export const viridischemadmin = createReducer(getDefaultState(), (builder) => {
    builder.addCase(getFeatures.success, (state, action) => {
        state.features = action.payload;
    });
    builder.addCase(getLicenses.success, (state, action) => {
        state.licenses = action.payload;
    });
    builder.addCase(getOrganizations.success, (state, action) => {
        state.organizations.organizations = action.payload.organizations;
        state.organizations.pages = action.payload.pages;
    });
    builder.addCase(getOrganizationFeatures.success, (state, action) => {
        const orgState = createIfNotExists(
            state,
            action.payload.organization_id
        );
        orgState.features = action.payload.features;
    });
    builder.addCase(setOrganizationFeature, (state, action) => {
        const orgState = createIfNotExists(
            state,
            action.payload.organization_id
        );

        const features = orgState.features;

        if (action.payload.enabled) {
            features.push(action.payload.feature);
        } else {
            features.splice(features.indexOf(action.payload.feature), 1);
        }
    });

    builder.addCase(setOrganizationProperty, (state, action) => {
        const orgState = createIfNotExists(
            state,
            action.payload.organization.id
        );

        if (!orgState.organization) {
            orgState.organization = {
                id: action.payload.organization.id,
            };
        }

        orgState.organization = {
            ...orgState.organization,
            ...action.payload.organization,
        };
    });

    builder.addCase(clearOrganization, (state, action) => {
        state.organizations.organizations_data[action.payload] = undefined;
    });

    builder.addCase(saveOrganization.success, (state, action) => {
        state.saved_timestamp = DateTime.now().toSeconds();
        state.organizations.organizations_data[action.payload.id] = undefined;
    });

    builder.addCase(createAccount.success, (state, action) => {
        state.saved_timestamp = DateTime.now().toSeconds();
        state.createAccountResult = action.payload;
    });

    builder.addCase(closeCreateAccountResult, (state) => {
        state.createAccountResult = undefined;
    });
});

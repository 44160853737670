import { createAction } from "@reduxjs/toolkit";

import { ILicense } from "../../components/licenses/Licenses";
import { createRsaaAction } from "../../components/utilities/ReduxUtils";
import { IUserState } from "../types/IUserState";

export const LIST_LICENSES: string = "LIST_LICENSES";
export const LIST_LICENSES_REQUEST: string = "LIST_LICENSES_REQUEST";
export const LIST_LICENSES_SUCCESS: string = "LIST_LICENSES_SUCCESS";
export const LIST_LICENSES_ERROR: string = "LIST_LICENSES_ERROR";
export const CREATE_TRIAL: string = "CREATE_TRIAL";
export const OPEN_SUBSCRIPTION_MODAL: string = "OPEN_SUBSCRIPTION_MODAL";
export const CLOSE_SUBSCRIPTION_MODAL: string = "CLOSE_SUBSCRIPTION_MODAL";
export const CREATE_SUBSCRIPTION: string = "CREATE_SUBSCRIPTION";
export const CREATE_SUBSCRIPTION_REQUEST: string =
    "CREATE_SUBSCRIPTION_REQUEST";
export const CREATE_SUBSCRIPTION_SUCCESS: string =
    "CREATE_SUBSCRIPTION_SUCCESS";
export const CREATE_SUBSCRIPTION_ERROR: string = "CREATE_SUBSCRIPTION_ERROR";

export const listLicenses = createRsaaAction({
    path: "billing/licenses",
    method: "POST",
    type: LIST_LICENSES,
});

export const createTrial = createRsaaAction<void, ICreateSubscriptionResponse>({
    path: "billing/licenses/trial",
    method: "POST",
    type: CREATE_TRIAL,
});

export const openSubscriptionModal = createAction<ILicense>(
    OPEN_SUBSCRIPTION_MODAL
);

export const closeSubscriptionModal = createAction(CLOSE_SUBSCRIPTION_MODAL);

export interface ICreateSubscriptionRequest {
    license_id: number;
    seats?: string;
}

export interface ICreateSubscriptionResponse {
    licenses: ILicense[];
    user: IUserState;
    invoiceUrl: string;
}

export const createSubscription = createRsaaAction<
    ICreateSubscriptionRequest,
    ICreateSubscriptionResponse
>({
    path: "billing/subscription",
    method: "POST",
    type: CREATE_SUBSCRIPTION,
});

import { DateTime, Duration, DurationObjectUnits } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { LOG_TO_CONSOLE } from "./FeatureFlags";

const emailCheck =
    // tslint:disable-next-line:max-line-length
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

export default class ViridisUtils {
    public static getUuid(): string {
        return uuidv4();
    }
    public static linkState(component, key: string, path?: string) {
        if (path) {
            return this.createHandler(component, key, path);
        }

        const cache =
            component.__linkStateHandlers ||
            (component.__linkStateHandlers = {});

        return cache[key] || (cache[key] = this.createHandler(component, key));
    }

    public static getParameterByName(
        name: string,
        path: string = location.search
    ): string {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
        const results = regex.exec(path);
        return results === null
            ? null
            : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    public static resolveAssetPath(path: string) {
        return `//assets-viridischem-com.s3.amazonaws.com/static/${path}`;
    }
    public static isString(s: any): boolean {
        return typeof s === "string" || s instanceof String;
    }
    public static isInteger(n: any): boolean {
        return Number.isInteger(n);
    }
    public static isNumeric(n: any): boolean {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }
    public static isDecimalNumber(n: any): boolean {
        const re = /^\d+(\.\d{1,2})?$/;
        // if value is not blank, then test the regex
        return n !== "" && re.test(n);
    }
    public static roundNumber(numberInput: any, decimals: number) {
        if (!this.isNumeric(numberInput)) {
            return numberInput;
        }

        const num: number = Number(numberInput);

        const roundedNum = Number(
            Math.round(Number(num + "e" + decimals)) + "e-" + decimals
        );

        if (isNaN(roundedNum)) {
            return num;
        } else {
            return roundedNum;
        }
    }
    public static validateEmail(email: string): boolean {
        return emailCheck.test(email);
    }
    public static showCitations() {
        return false;
    }
    public static log(message: any) {
        if (LOG_TO_CONSOLE()) {
            // tslint:disable-next-line no-console
            console.log(message);
        }
    }
    public static logObject(message: string, object: any) {
        if (LOG_TO_CONSOLE()) {
            // tslint:disable-next-line no-console
            console.log(`${message}: ${JSON.stringify(object)}`);
        }
    }

    public static humanReadableEpochSecond(
        epochSeconds: number,
        reverse: boolean = false
    ) {
        if (!epochSeconds) {
            return "";
        }

        const dt = DateTime.fromSeconds(epochSeconds);
        const formattedDt = dt.toLocaleString(DateTime.DATETIME_MED);

        if (reverse) {
            return `${formattedDt} (${this.toHumanDuration(
                dt,
                DateTime.now()
            )} ago)`;
        } else {
            return `${formattedDt} (in ${this.toHumanDuration(
                DateTime.now(),
                dt
            )})`;
        }
    }

    private static toHumanDuration(start: DateTime, end: DateTime): string {
        // Better Duration.toHuman support https://github.com/moment/luxon/issues/1134
        const duration = end
            .diff(start)
            .shiftTo("years", "days", "hours", "minutes", "seconds")
            .toObject();

        if ("seconds" in duration) {
            duration.seconds = Math.round(duration.seconds);
        }

        const cleanedDuration = Object.fromEntries(
            Object.entries(duration).filter(([_k, v]) => v !== 0)
        ) as DurationObjectUnits;

        if (Object.keys(cleanedDuration).length === 0) {
            cleanedDuration.seconds = 0;
        }

        return Duration.fromObject(cleanedDuration).toHuman();
    }

    public static copyToClipboard(data: any): void {
        navigator.clipboard.writeText(data);
    }

    private static createHandler(component, key: string, path?: string) {
        return (e) => {
            const el = e.target;
            const value = el.type === "checkbox" ? el.checked : el.value;
            component.setState({
                [key]: path ? component.state[key].setIn(path, value) : value,
            });
        };
    }
}

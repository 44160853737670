import { createReducer } from "@reduxjs/toolkit";

import {
    addAdvancedSearchItem,
    clearAdvancedSearchData,
    removeAdvancedSearchItem,
    updateAdvancedSearchItem,
} from "../../actions/advancedsearch";

export interface IAdvancedSearchItemSpecification {
    title: string;
    name: string;
    type: string;
    url?: string;
    allow_multiple?: boolean;
}

export interface IAdvancedSearchDataElement {
    [key: string]: any;
}

export interface IAdvancedSearchData {
    key: string;
    name: string;
    specification: IAdvancedSearchItemSpecification;
    data: IAdvancedSearchDataElement;
}

export interface IAdvancedSearchReduxState {
    [key: string]: IAdvancedSearchData;
}

export const getDefaultState = (): IAdvancedSearchReduxState => {
    return {};
};

export const advancedsearch = createReducer(getDefaultState(), (builder) => {
    builder
        .addCase(
            addAdvancedSearchItem,
            (state: IAdvancedSearchReduxState, action) => {
                state[action.payload.key] = action.payload;
            }
        )
        .addCase(
            updateAdvancedSearchItem,
            (state: IAdvancedSearchReduxState, action) => {
                state[action.payload.key].data = Object.assign(
                    {},
                    state[action.payload.key].data,
                    action.payload.data
                );
            }
        )
        .addCase(
            removeAdvancedSearchItem,
            (state: IAdvancedSearchReduxState, action) => {
                const key = action.payload;
                delete state[key];
            }
        )
        .addCase(clearAdvancedSearchData, () => {
            return getDefaultState();
        });
});

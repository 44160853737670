import { createAction } from "@reduxjs/toolkit";

import { ISearchRequest } from "../../components/application/search/SearchBar";
import { createRsaaAction } from "../../components/utilities/ReduxUtils";
import IChemicalSearchModelResult from "../../data/ChemicalSearchModelResult";
import {
    IReaction,
    IReactionChemical,
    IReactionPropertyResponse,
} from "../reducers/reactions";

export const REACTIONS_SHOW_EDIT_MODAL = "REACTIONS_SHOW_EDIT_MODAL";
export const REACTIONS_SUBMIT_EDIT_MODAL = "REACTIONS_SUBMIT_EDIT_MODAL";
export const REACTIONS_HIDE_EDIT_MODAL = "REACTIONS_HIDE_EDIT_MODAL";

export const REACTIONS_SHOW_LOAD_MODAL = "REACTIONS_SHOW_LOAD_MODAL";
export const REACTIONS_SUBMIT_LOAD_MODAL = "REACTIONS_SUBMIT_LOAD_MODAL";
export const REACTIONS_HIDE_LOAD_MODAL = "REACTIONS_HIDE_LOAD_MODAL";

export const REACTIONS_SHOW_CHEMICAL_EDIT_MODAL =
    "REACTIONS_SHOW_CHEMICAL_EDIT_MODAL";
export const REACTIONS_SUBMIT_CHEMICAL_EDIT_MODAL =
    "REACTIONS_SUBMIT_CHEMICAL_EDIT_MODAL";
export const REACTIONS_HIDE_CHEMICAL_EDIT_MODAL =
    "REACTIONS_HIDE_CHEMICAL_EDIT_MODAL";
export const REACTIONS_REMOVE_REACTION = "REACTIONS_REMOVE_REACTION";
export const REACTIONS_SHOW_UPLOAD_MODAL = "REACTIONS_SHOW_UPLOAD_MODAL";
export const REACTIONS_HIDE_UPLOAD_MODAL = "REACTIONS_HIDE_UPLOAD_MODAL";

export const REACTIONS_ANALYZE = "REACTIONS_ANALYZE";

export const REACTION_SET_NAME = "REACTION_SET_NAME";
export const REACTION_SET_DESCRIPTION = "REACTION_SET_DESCRIPTION";
export const REACTION_REMOVE_REACTION_CHEMICAL =
    "REACTION_REMOVE_REACTION_CHEMICAL";

export const REACTION_CHEMICAL_SET_NAME = "REACTION_CHEMICAL_SET_NAME";
export const REACTION_CHEMICAL_SET_DESCRIPTION =
    "REACTION_CHEMICAL_SET_DESCRIPTION";
export const REACTION_CHEMICAL_SET_CHEMICAL_TYPE =
    "REACTION_CHEMICAL_SET_CHEMICAL_TYPE";
export const REACTION_CHEMICAL_SET_MASS_VALUE =
    "REACTION_CHEMICAL_SET_MASS_VALUE";
export const REACTION_CHEMICAL_SET_MASS_UNIT =
    "REACTION_CHEMICAL_SET_MASS_UNIT";

export const REACTION_CHEMICAL_SEARCH = "REACTION_CHEMICAL_SEARCH";

export const REACTION_CHEMICAL_REMOVE_LINKED_CHEMICAL =
    "REACTION_CHEMICAL_REMOVE_LINKED_CHEMICAL";
export const REACTION_CHEMICAL_SUBMIT_LINKED_REACTION =
    "REACTION_CHEMICAL_SUBMIT_LINKED_REACTION";
export const REACTION_CHEMICAL_REMOVE_LINKED_REACTION =
    "REACTION_CHEMICAL_REMOVE_LINKED_REACTION";

export const REACTION_CHEMICAL_SHOW_REACTION_LINK_MODAL =
    "REACTION_CHEMICAL_SHOW_REACTION_LINK_MODAL";
export const REACTION_CHEMICAL_HIDE_REACTION_LINK_MODAL =
    "REACTION_CHEMICAL_HIDE_REACTION_LINK_MODAL";

export const REACTION_CHEMICAL_GET_CHEMICAL_TYPES =
    "REACTION_CHEMICAL_GET_CHEMICAL_TYPES";

export const showReactionsEditModal = createAction<IReaction>(
    REACTIONS_SHOW_EDIT_MODAL
);

export const submitReactionsEditModal = createAction<IReaction>(
    REACTIONS_SUBMIT_EDIT_MODAL
);

export const hideReactionsEditModal = createAction(REACTIONS_HIDE_EDIT_MODAL);

export const showReactionsLoadModal = createAction(REACTIONS_SHOW_LOAD_MODAL);
export const submitReactionsLoadModal = createAction<IReaction>(
    REACTIONS_SUBMIT_LOAD_MODAL
);
export const hideReactionsLoadModal = createAction(REACTIONS_HIDE_LOAD_MODAL);

export const removeReaction = createAction<IReaction>(
    REACTIONS_REMOVE_REACTION
);

export const showReactionChemicalEditModal = createAction<IReactionChemical>(
    REACTIONS_SHOW_CHEMICAL_EDIT_MODAL
);

export const submitReactionChemicalEditModal = createAction<IReactionChemical>(
    REACTIONS_SUBMIT_CHEMICAL_EDIT_MODAL
);

export const hideReactionChemicalEditModal = createAction(
    REACTIONS_HIDE_CHEMICAL_EDIT_MODAL
);

export const showReactionChemicalReactionLinkModal = createAction(
    REACTION_CHEMICAL_SHOW_REACTION_LINK_MODAL
);

export const hideReactionChemicalReactionLinkModal = createAction(
    REACTION_CHEMICAL_HIDE_REACTION_LINK_MODAL
);

export const showUploadModal = createAction(REACTIONS_SHOW_UPLOAD_MODAL);

export const hideUploadModal = createAction(REACTIONS_HIDE_UPLOAD_MODAL);

export const analyzeReaction = createRsaaAction<
    IReaction,
    IReactionPropertyResponse
>({
    path: "reactions/analyze",
    method: "POST",
    type: REACTIONS_ANALYZE,
});

export const setReactionName = createAction<string>(REACTION_SET_NAME);
export const setReactionDescription = createAction<string>(
    REACTION_SET_DESCRIPTION
);
export const removeReactionChemical = createAction<IReactionChemical>(
    REACTION_REMOVE_REACTION_CHEMICAL
);

export const setReactionChemicalName = createAction<string>(
    REACTION_CHEMICAL_SET_NAME
);
export const setReactionChemicalDescription = createAction<string>(
    REACTION_CHEMICAL_SET_DESCRIPTION
);
export const setReactionChemicalType = createAction<string>(
    REACTION_CHEMICAL_SET_CHEMICAL_TYPE
);
export const setReactionChemicalMassValue = createAction<number>(
    REACTION_CHEMICAL_SET_MASS_VALUE
);
export const setReactionChemicalMassUnit = createAction<string>(
    REACTION_CHEMICAL_SET_MASS_UNIT
);

export const searchReactionChemical = createRsaaAction<
    ISearchRequest,
    IChemicalSearchModelResult
>({
    path: "chemicals/search",
    method: "POST",
    type: REACTION_CHEMICAL_SEARCH,
});

export const getReactionChemicalTypes = createRsaaAction<void, string[]>({
    path: "reactions/chemical_types",
    method: "GET",
    type: REACTION_CHEMICAL_GET_CHEMICAL_TYPES,
});

export const removeReactionChemicalLinkedChemical = createAction(
    REACTION_CHEMICAL_REMOVE_LINKED_CHEMICAL
);

export const submitReactionChemicalLinkedReaction = createAction<string>(
    REACTION_CHEMICAL_SUBMIT_LINKED_REACTION
);

export const removeReactionChemicalLinkedReaction = createAction(
    REACTION_CHEMICAL_REMOVE_LINKED_REACTION
);

import { createReducer } from "@reduxjs/toolkit";
import { IChemicalModel } from "../../data/ChemicalModel";
import { logOut } from "../actions/user";
import { closeWarningList, openWarningList } from "../actions/warninglist";

export interface IWarningListState {
    show: boolean;
    compound: IChemicalModel;
}

const getDefaultState = (): IWarningListState => {
    return {
        show: false,
        compound: null,
    };
};

export const warninglist = createReducer(getDefaultState(), (builder) => {
    builder.addCase(openWarningList, (state, action) => {
        state.show = true;
        state.compound = action.payload;
    });
    builder.addCase(closeWarningList, (state) => {
        state.show = false;
        state.compound = null;
    });
    builder.addCase(logOut.request, () => {
        return getDefaultState();
    });
});

import { agent } from "./agent";
import { apiCache } from "./apiCache";
import { apiKeys } from "./apiKeys";
import { awsMarketplace } from "./awsmarketplace";
import { categories } from "./categories";
import { chempredictor } from "./chempredictor";
import { citations } from "./citations";
import { feedbackForm } from "./feedbackForm";
import { licenses } from "./licenses";
import { listitems } from "./listitems";
import { loginPage } from "./loginPage";
import { myAccountPage } from "./myAccountPage";
import { persistent } from "./persistent/index";
import { scoreWeightNames } from "./persistent/scoreWeights";
import { pocketbook } from "./pocketbook";
import { reactions } from "./reactions";
import { searchbar } from "./searchbar";
import { user } from "./user";
import { viridischemadmin } from "./viridischemadmin";
import { warninglist } from "./warninglist";

export default {
    agent,
    apiCache,
    apiKeys,
    awsMarketplace,
    categories,
    warninglist,
    listitems,
    searchbar,
    user,
    loginPage,
    pocketbook,
    chempredictor,
    citations,
    feedbackForm,
    myAccountPage,
    persistent,
    licenses,
    reactions,
    scoreWeightNames,
    viridischemadmin,
};

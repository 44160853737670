import { createAction } from "@reduxjs/toolkit";
import { createRsaaAction } from "../../components/utilities/ReduxUtils";
import { createSocketAction } from "../middlewares/websocket";

export const LLM_CONVERSATION_START = "LLM_CONVERSATION_START";
export const LLM_CONVERSATION_STOP = "LLM_CONVERSATION_STOP";
export const LLM_CONVERSATION_MESSAGE = "LLM_CONVERSATION_MESSAGE";
export const LLM_CONVERSATION_MESSAGE_STREAM =
    "LLM_CONVERSATION_MESSAGE_STREAM";
export const LLM_OPEN_FEEDBACK_FORM: string = "LLM_OPEN_FEEDBACK_FORM";
export const LLM_CLOSE_FEEDBACK_FORM: string = "LLM_CLOSE_FEEDBACK_FORM";
export const LLM_SET_FEEDBACK: string = "LLM_SET_FEEDBACK";
export const LLM_SUBMIT_FEEDBACK: string = "LLM_SUBMIT_FEEDBACK";

export interface IAgentConversationStartResponse {
    conversation_id: string;
}

export interface IAgentConversationRequest {
    message: IAgentMessage;
}

export interface IAgentMessage {
    conversation_id?: string;
    stream_id?: string;
    message_id: string;
    message_index?: number;
    token_index?: number;
    role?: string;
    content?: string;
}

export const agentConversationStart = createSocketAction<
    void,
    IAgentConversationStartResponse
>({
    type: LLM_CONVERSATION_START,
});

export const agentConversationStop = createSocketAction({
    type: LLM_CONVERSATION_STOP,
});

export const agentConversationSend = createSocketAction<
    IAgentConversationRequest,
    IAgentMessage
>({
    type: LLM_CONVERSATION_MESSAGE,
});

export const agentConversationStream = createSocketAction<
    IAgentConversationRequest,
    IAgentMessage
>({
    type: LLM_CONVERSATION_MESSAGE_STREAM,
});

export interface IAgentFeedbackRequest {
    conversation_id: string;
    message_id: string;
    messages: IAgentMessage[];
    feedback: string;
}

export const submitAgentFeedback = createRsaaAction<IAgentFeedbackRequest>({
    path: "feedback/agent",
    method: "POST",
    type: LLM_SUBMIT_FEEDBACK,
});

export const openAgentFeedback = createAction(LLM_OPEN_FEEDBACK_FORM);

export const closeAgentFeedback = createAction(LLM_CLOSE_FEEDBACK_FORM);

export const setAgentFeedback = createAction<string>(LLM_SET_FEEDBACK);

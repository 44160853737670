import { Middleware } from "redux";

import { PayloadAction } from "@reduxjs/toolkit";
import { AnyAction } from "redux-saga";
import {
    IRsaaAction,
    RSAA_SYMBOL,
} from "../../components/utilities/ReduxUtils";
import { createFetchInstance } from "../fetch";

interface IActionCache {
    [key: string]: AnyAction;
}

const actionCache: IActionCache = {};

export const rsaaExtenderMiddleware: Middleware =
    (store) => (next) => (action: IRsaaAction<any>) => {
        if (action[RSAA_SYMBOL]) {
            action[RSAA_SYMBOL].fetch = createFetchInstance(store).fetch;

            if (!action[RSAA_SYMBOL].credentials) {
                action[RSAA_SYMBOL].credentials = "same-origin";
            }

            if (action.meta && action.meta.cache) {
                action.meta.cache_key =
                    action.meta.cache_key ??
                    `${action[RSAA_SYMBOL].endpoint}|${JSON.stringify(action[RSAA_SYMBOL].body)}`;

                if (actionCache[action.meta.cache_key]) {
                    return next(actionCache[action.meta.cache_key]);
                }
            }

            if (action[RSAA_SYMBOL].types) {
                action[RSAA_SYMBOL].types = action[RSAA_SYMBOL].types.map(
                    (type) => {
                        return {
                            type,
                            meta: {
                                rsaa_endpoint: action[RSAA_SYMBOL].endpoint,
                                ...action.meta,
                            },
                        };
                    }
                );
            }

            if (action[RSAA_SYMBOL].body) {
                if (action[RSAA_SYMBOL].body instanceof Object) {
                    action[RSAA_SYMBOL].body = JSON.stringify(
                        action[RSAA_SYMBOL].body
                    );
                }
            }
        }

        return next(action);
    };

export const cacheMiddleware: Middleware =
    (store) => (next) => (action: IRsaaAction<any> & PayloadAction<any>) => {
        if (
            action.meta &&
            action.meta.cache_key &&
            !actionCache[action.meta.cache_key] &&
            action.payload
        ) {
            actionCache[action.meta.cache_key] = action;
        }

        next(action);
    };

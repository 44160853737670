import { createReducer } from "@reduxjs/toolkit";

import { IChemicalModel } from "../../data/ChemicalModel";
import { closeCategories, openCategories } from "../actions/categories";
import { logOut } from "../actions/user";

interface ICategoriesState {
    show: boolean;
    compound?: IChemicalModel;
}

const getDefaultState = (): ICategoriesState => {
    return { show: false };
};

export const categories = createReducer(getDefaultState(), (builder) => {
    builder
        .addCase(openCategories, (state, action) => {
            state.show = true;
            state.compound = action.payload;
        })
        .addCase(closeCategories, (state) => {
            state.show = false;
            state.compound = null;
        })
        .addCase(logOut.request, () => {
            return getDefaultState();
        });
});

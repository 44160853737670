import { createReducer } from "@reduxjs/toolkit";

import {
    getScoreWeights,
    removeScoreWeight,
    resetScoreWeights,
    setScoreWeight,
} from "../../actions/scoreWeights";

export interface IScoreWeightName {
    name: string;
    value: string;
}

export interface IScoreWeights {
    [name: string]: number;
}

interface IScoreWeightNamesState {
    names: IScoreWeightName[];
}

interface IScoreWeightsState {
    weights: IScoreWeights;
}

export const getDefaultNamesState = (): IScoreWeightNamesState => ({
    names: [],
});

// Names reducer is not under persistent
export const scoreWeightNames = createReducer(
    getDefaultNamesState(),
    (builder) => {
        builder.addCase(
            getScoreWeights.success,
            (state: IScoreWeightNamesState, action) => {
                state.names = action.payload;
            }
        );
    }
);

export const getDefaultState = (): IScoreWeightsState => ({ weights: {} });

export const scoreWeights = createReducer({ weights: {} }, (builder) => {
    builder
        .addCase(setScoreWeight, (state: IScoreWeightsState, action) => {
            state.weights[action.payload.name] = action.payload.weight;
        })
        .addCase(removeScoreWeight, (state: IScoreWeightsState, action) => {
            delete state.weights[action.payload];
        })
        .addCase(resetScoreWeights, () => {
            return getDefaultState();
        });
});

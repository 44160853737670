import ViridisUtils from "../../components/utilities/ViridisUtils";

function* logActions(action) {
    ViridisUtils.logObject("Saga action", action);
    yield;
}

export default function* logActionsWatcher() {
    // yield all([takeEvery("*", logActions)]);
}

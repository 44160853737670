import { createAction } from "@reduxjs/toolkit";

import { IChemicalModel } from "../../data/ChemicalModel";

export const OPEN_WARNINGLIST: string = "OPEN_WARNINGLIST";
export const CLOSE_WARNINGLIST: string = "CLOSE_WARNINGLIST";

export const openWarningList = createAction<IChemicalModel>(OPEN_WARNINGLIST);

export const closeWarningList = createAction(CLOSE_WARNINGLIST);

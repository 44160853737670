import { createAction } from "@reduxjs/toolkit";

import { createRsaaAction } from "../../components/utilities/ReduxUtils";

export const LOGIN_SET_ERROR: string = "LOGIN_SET_ERROR";
export const EMAIL_VALIDATE_RESEND: string = "EMAIL_VALIDATE_RESEND";
export const EMAIL_VALIDATE_RESEND_REQUEST: string =
    "EMAIL_VALIDATE_RESEND_REQUEST";
export const EMAIL_VALIDATE_RESEND_SUCCESS: string =
    "EMAIL_VALIDATE_RESEND_SUCCESS";
export const EMAIL_VALIDATE_RESEND_ERROR: string =
    "EMAIL_VALIDATE_RESEND_ERROR";

export const setError = createAction<string>(LOGIN_SET_ERROR);

export interface ILoginEmailValidateResend {
    email: string;
}

export const emailValidateResend = createRsaaAction<ILoginEmailValidateResend>({
    path: "validateResend",
    method: "POST",
    type: EMAIL_VALIDATE_RESEND,
});

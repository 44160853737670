import { createReducer } from "@reduxjs/toolkit";
import { setError } from "../actions/loginPage";
import { logIn } from "../actions/user";

interface ILoginPageState {
    busy: boolean;
    error?: string;
}

const getDefaultState = (): ILoginPageState => ({
    busy: false,
});

export const loginPage = createReducer(getDefaultState(), (builder) => {
    builder.addCase(setError, (state, action) => {
        state.busy = false;
        state.error = action.payload;
    });
    builder.addCase(logIn.request, (state) => {
        state.busy = true;
    });
    builder.addCase(logIn.success, (state) => {
        state.busy = false;
        state.error = undefined;
    });
    builder.addCase(logIn.error, (state, action) => {
        state.busy = false;
        state.error = action.payload.response.response;
    });
});

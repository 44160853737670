import { createAction } from "@reduxjs/toolkit";

import { createRsaaAction } from "../../components/utilities/ReduxUtils";
import { IScoreWeightName } from "../reducers/persistent/scoreWeights";

export const SCORE_WEIGHTS_GET_ENDPOINT_NAMES =
    "SCORE_WEIGHTS_GET_ENDPOINT_NAMES";
export const SCORE_WEIGHTS_SET = "SCORE_WEIGHTS_SET";
export const SCORE_WEIGHTS_REMOVE = "SCORE_WEIGHTS_REMOVE";
export const SCORE_WEIGHTS_RESET = "SCORE_WEIGHTS_RESET";

export interface IScoreWeight {
    name: string;
    weight: number;
}

export const getScoreWeights = createRsaaAction<void, IScoreWeightName[]>({
    path: "scores/names",
    method: "GET",
    type: SCORE_WEIGHTS_GET_ENDPOINT_NAMES,
});

export const setScoreWeight = createAction<IScoreWeight>(SCORE_WEIGHTS_SET);

export const removeScoreWeight = createAction<string>(SCORE_WEIGHTS_REMOVE);

export const resetScoreWeights = createAction(SCORE_WEIGHTS_RESET);

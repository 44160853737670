"use strict";

import "promise-polyfill/src/polyfill";
import "whatwg-fetch";

import React from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
// tslint:disable-next-line:no-submodule-imports
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { PersistGate } from "redux-persist/integration/react";
import { i18n } from "../i18n/i18next";
import { history, persistor, store } from "../redux/store";
// tslint:disable-next-line:no-submodule-imports

const mountDivName = "react-container";
let container;

export function render(Component) {
    if (container) {
        return;
    }

    container = document.getElementById(mountDivName);
    const root = createRoot(container);
    root.render(
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Component />
                </PersistGate>
            </Provider>
        </I18nextProvider>
    );
}

export function routerRender(Component) {
    if (container) {
        return;
    }

    container = document.getElementById(mountDivName);
    const root = createRoot(container);
    root.render(
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Router history={history}>
                        <Component />
                    </Router>
                </PersistGate>
            </Provider>
        </I18nextProvider>
    );
}

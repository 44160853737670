import { createReducer } from "@reduxjs/toolkit";

import ViridisUtils from "../../components/utilities/ViridisUtils";
import { ISearchResultsData } from "../../data/ChemicalSearchModel";
import {
    analyzeReaction,
    getReactionChemicalTypes,
    hideReactionChemicalEditModal,
    hideReactionChemicalReactionLinkModal,
    hideReactionsEditModal,
    hideReactionsLoadModal,
    hideUploadModal,
    removeReactionChemical,
    removeReactionChemicalLinkedChemical,
    removeReactionChemicalLinkedReaction,
    searchReactionChemical,
    setReactionChemicalDescription,
    setReactionChemicalMassUnit,
    setReactionChemicalMassValue,
    setReactionChemicalName,
    setReactionChemicalType,
    setReactionDescription,
    setReactionName,
    showReactionChemicalEditModal,
    showReactionChemicalReactionLinkModal,
    showReactionsEditModal,
    showReactionsLoadModal,
    showUploadModal,
    submitReactionChemicalEditModal,
    submitReactionChemicalLinkedReaction,
    submitReactionsEditModal,
    submitReactionsLoadModal,
} from "../actions/reactions";

export interface IMultiStepReaction {
    ui_id: string;
    steps: IReaction[];
}

export interface IReaction {
    ui_id: string;
    name: string;
    description?: string;
    reaction_type?: string;
    chemicals: IReactionChemical[];
}

export interface IShownReaction {
    reaction: IReaction;
    properties: IReactionPropertyMap;
}

export interface IReactionPropertyResponse {
    properties: IReactionPropertyMap;
}

export interface IReactionPropertyMap {
    [key: string]: IReactionProperty;
}

export interface IReactionProperty {
    name: string;
    string_value: string;
    double_value: number;
}

export interface IReactionChemical {
    ui_id: string;
    name: string;
    description?: string;
    chemical_type?: string;
    mass_value?: number;
    mass_unit?: string;
    percentage?: number;
    chemical?: ISearchResultsData;
    reaction_ui_id?: string;
    is_fragment?: boolean;
}

export interface IReactionsState {
    showEditModal: boolean;
    showLoadModal: boolean;
    showChemicalEditModal: boolean;
    showReactionChemicalReactionLinkModal: boolean;
    showUploadModal: boolean;
    shownReaction: IShownReaction;
    currentReaction: IReaction;
    currentReactionChemical: IReactionChemical;
    chemical_types: string[];
}

const getDefaultState = (): IReactionsState => {
    return {
        showEditModal: false,
        showLoadModal: false,
        showChemicalEditModal: false,
        showReactionChemicalReactionLinkModal: false,
        showUploadModal: false,
        shownReaction: null,
        currentReaction: getDefaultReaction(),
        currentReactionChemical: getDefaultReactionChemical(),
        chemical_types: [],
    };
};

const getDefaultReaction = (): IReaction => ({
    ui_id: ViridisUtils.getUuid(),
    name: "",
    chemicals: [],
});

const getShownReaction = (reaction?: IReaction): IShownReaction => ({
    reaction,
    properties: {},
});

const getDefaultReactionChemical = (): IReactionChemical => ({
    ui_id: ViridisUtils.getUuid(),
    name: "",
    is_fragment: false,
});

export const reactions = createReducer(getDefaultState(), (builder) => {
    builder.addCase(showReactionsEditModal, (state, action) => {
        state.showEditModal = true;
        if (action.payload) {
            state.currentReaction = action.payload;
        } else {
            state.currentReaction = getDefaultReaction();
        }
    });
    builder.addCase(submitReactionsEditModal, (state, action) => {
        state.shownReaction = getShownReaction(action.payload);
        state.showEditModal = false;
    });
    builder.addCase(hideReactionsEditModal, (state) => {
        state.showEditModal = false;
    });
    builder.addCase(showReactionsLoadModal, (state) => {
        state.showLoadModal = true;
    });
    builder.addCase(submitReactionsLoadModal, (state, action) => {
        state.shownReaction = getShownReaction(action.payload);
        state.showLoadModal = false;
    });
    builder.addCase(hideReactionsLoadModal, (state) => {
        state.showLoadModal = false;
    });
    builder.addCase(showReactionChemicalEditModal, (state, action) => {
        state.showChemicalEditModal = true;
        if (action.payload) {
            state.currentReactionChemical = action.payload;
        }
    });
    builder.addCase(submitReactionChemicalEditModal, (state, action) => {
        const existingIndex = state.currentReaction.chemicals.findIndex(
            (c) => c.ui_id === action.payload.ui_id
        );
        if (existingIndex >= 0) {
            state.currentReaction.chemicals[existingIndex] = action.payload;
        } else {
            state.currentReaction.chemicals.push(action.payload);
        }
        state.showChemicalEditModal = false;
        state.shownReaction = getShownReaction(state.currentReaction);
        state.currentReactionChemical = getDefaultReactionChemical();
    });
    builder.addCase(hideReactionChemicalEditModal, (state) => {
        state.showChemicalEditModal = false;
        state.currentReactionChemical = getDefaultReactionChemical();
    });
    builder.addCase(showReactionChemicalReactionLinkModal, (state) => {
        state.showReactionChemicalReactionLinkModal = true;
    });
    builder.addCase(hideReactionChemicalReactionLinkModal, (state) => {
        state.showReactionChemicalReactionLinkModal = false;
    });
    builder.addCase(showUploadModal, (state) => {
        state.showUploadModal = true;
    });
    builder.addCase(hideUploadModal, (state) => {
        state.showUploadModal = false;
    });
    builder.addCase(setReactionName, (state, action) => {
        state.currentReaction.name = action.payload;
    });
    builder.addCase(setReactionDescription, (state, action) => {
        state.currentReaction.description = action.payload;
    });
    builder.addCase(removeReactionChemical, (state, action) => {
        const existingIndex = state.currentReaction.chemicals.findIndex(
            (c) => c.ui_id === action.payload.ui_id
        );
        if (existingIndex >= 0) {
            state.currentReaction.chemicals.splice(existingIndex, 1);
        }
    });
    builder.addCase(setReactionChemicalName, (state, action) => {
        state.currentReactionChemical.name = action.payload;
    });
    builder.addCase(setReactionChemicalDescription, (state, action) => {
        state.currentReactionChemical.description = action.payload;
    });
    builder.addCase(setReactionChemicalType, (state, action) => {
        state.currentReactionChemical.chemical_type = action.payload;
    });
    builder.addCase(setReactionChemicalMassValue, (state, action) => {
        state.currentReactionChemical.mass_value = action.payload;
    });
    builder.addCase(setReactionChemicalMassUnit, (state, action) => {
        state.currentReactionChemical.mass_unit = action.payload;
    });
    builder.addCase(searchReactionChemical.success, (state, action) => {
        if (action?.payload?.models?.length === 1) {
            state.currentReactionChemical.chemical = action.payload.models[0];
        }
    });
    builder.addCase(removeReactionChemicalLinkedChemical, (state) => {
        state.currentReactionChemical.chemical = undefined;
    });
    builder.addCase(submitReactionChemicalLinkedReaction, (state, action) => {
        state.currentReactionChemical.reaction_ui_id = action.payload;
        state.showReactionChemicalReactionLinkModal = false;
    });
    builder.addCase(removeReactionChemicalLinkedReaction, (state) => {
        state.currentReactionChemical.reaction_ui_id = undefined;
    });
    builder.addCase(getReactionChemicalTypes.success, (state, action) => {
        state.chemical_types = action.payload;
    });
    builder.addCase(analyzeReaction.success, (state, action) => {
        state.shownReaction.properties = action.payload.properties;
    });
});

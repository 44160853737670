import { createAction } from "@reduxjs/toolkit";

import { createRsaaAction } from "../../components/utilities/ReduxUtils";

export const OPEN_FEEDBACK_FORM: string = "OPEN_FEEDBACK_FORM";
export const CLOSE_FEEDBACK_FORM: string = "CLOSE_FEEDBACK_FORM";
export const SET_FEEDBACK: string = "SET_FEEDBACK";
export const SET_FEEDBACK_COMPOUND: string = "SET_FEEDBACK_COMPOUND";
export const SUBMIT_FEEDBACK: string = "SUBMIT_FEEDBACK";

export interface IFeedbackRequest {
    compound_id: number;
    feedback: string;
}

export const submitFeedback = createRsaaAction<IFeedbackRequest>({
    path: "feedback",
    method: "POST",
    type: SUBMIT_FEEDBACK,
});

export const openFeedback = createAction(OPEN_FEEDBACK_FORM);

export const closeFeedback = createAction(CLOSE_FEEDBACK_FORM);

export const setFeedback = createAction<string>(SET_FEEDBACK);

export const setCompound = createAction<number>(SET_FEEDBACK_COMPOUND);

import { createReducer } from "@reduxjs/toolkit";
import { AnyAction } from "redux";

import {
    API_CACHE_SEND_ERROR,
    API_CACHE_SEND_SUCCESS,
    ICacheRequest,
    promiseMap,
} from "../actions/apiCache";

export const apiCache = createReducer({}, (builder) => {
    let request: ICacheRequest;
    builder
        .addCase(API_CACHE_SEND_SUCCESS, (state, action: AnyAction) => {
            request = promiseMap[action.meta.context][action.meta.key];
            request.resolve(action.payload);
        })
        .addCase(API_CACHE_SEND_ERROR, (state, action: AnyAction) => {
            request = promiseMap[action.meta.context][action.meta.key];
            request.reject(action.payload.response);
        });
});

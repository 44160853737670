import { createRsaaAction } from "../../components/utilities/ReduxUtils";

export const CHANGE_EMAIL: string = "CHANGE_EMAIL";
export const CHANGE_EMAIL_REQUEST: string = "CHANGE_EMAIL_REQUEST";
export const CHANGE_EMAIL_SUCCESS: string = "CHANGE_EMAIL_SUCCESS";
export const CHANGE_EMAIL_ERROR: string = "CHANGE_EMAIL_ERROR";
export const CHANGE_PASSWORD: string = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_REQUEST: string = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS: string = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR: string = "CHANGE_PASSWORD_ERROR";

export interface IMyAccountSetEmailRequest {
    email: string;
}

export interface IMyAccountSetResponse {
    error: string;
}

export const setEmail = createRsaaAction<
    IMyAccountSetEmailRequest,
    IMyAccountSetResponse
>({
    path: "users/email",
    method: "POST",
    type: CHANGE_EMAIL,
});

export interface IMyAccountSetPasswordRequest {
    oldPassword: string;
    password: string;
}

export const setPassword = createRsaaAction<
    IMyAccountSetPasswordRequest,
    IMyAccountSetResponse
>({
    path: "users/password",
    method: "POST",
    type: CHANGE_PASSWORD,
});

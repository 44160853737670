import { PayloadAction, createReducer } from "@reduxjs/toolkit";
import {
    IAgentMessage,
    agentConversationSend,
    agentConversationStart,
    agentConversationStop,
    agentConversationStream,
    closeAgentFeedback,
    openAgentFeedback,
    setAgentFeedback,
    submitAgentFeedback,
} from "../actions/agent";
import { logOut } from "../actions/user";

export interface IAgentState {
    conversation_id?: string;
    message_ids: string[];
    messages: { [key: string]: IAgentMessage };
    showFeedback: boolean;
    doneFeedback: boolean;
    feedback: string;
}

const getDefaultState = (): IAgentState => {
    return {
        message_ids: [],
        messages: {},
        showFeedback: false,
        doneFeedback: false,
        feedback: undefined,
    };
};

const mergeMessage = (
    state: IAgentState,
    action: PayloadAction<IAgentMessage>,
    append: boolean = false
) => {
    const messageId = action.payload.message_id;
    if (messageId in state.messages) {
        if (append) {
            state.messages[messageId].content =
                state.messages[messageId].content + action.payload.content;
        } else {
            state.messages[messageId] = action.payload;
        }
    } else {
        state.message_ids.push(messageId);
        state.messages[messageId] = action.payload;
    }
};

export const agent = createReducer(getDefaultState(), (builder) => {
    builder.addCase(agentConversationStart.success, (state, action) => {
        state.conversation_id = action.payload.conversation_id;
    });
    builder.addCase(agentConversationStop.success, () => {
        return getDefaultState();
    });
    builder.addCase(agentConversationSend.request, (state, action) => {
        mergeMessage(state, action);
    });
    builder.addCase(agentConversationSend.success, (state, action) => {
        mergeMessage(state, action);
    });
    builder.addCase(agentConversationStream.request, (state, action) => {
        mergeMessage(state, action);
    });
    builder.addCase(agentConversationStream.update, (state, action) => {
        mergeMessage(state, action, true);
    });
    builder.addCase(agentConversationStream.success, (state, action) => {
        mergeMessage(state, action, true);
    });
    builder.addCase(openAgentFeedback, (state) => {
        state.showFeedback = true;
        state.doneFeedback = false;
        state.feedback = null;
    });
    builder.addCase(closeAgentFeedback, (state) => {
        state.showFeedback = false;
        state.doneFeedback = false;
        state.feedback = null;
    });
    builder.addCase(setAgentFeedback, (state, action) => {
        state.feedback = action.payload;
    });
    builder.addCase(submitAgentFeedback.success, (state) => {
        state.doneFeedback = true;
    });
    builder.addCase(logOut.request, () => {
        return getDefaultState();
    });
});

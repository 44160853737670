import { createAction, PayloadActionCreator } from "@reduxjs/toolkit";
import { Action, Middleware, MiddlewareAPI } from "redux";

import { send, ws } from "../websocket";

export const SOCKET_EVENT_NAME = "redux_event";
export const SOCKET_EVENT_RESPONSE_NAME = "redux_response";

export interface ISocketMiddlewareAction<P> extends Action {
    socket: {
        types: string[];
    };
    payload?: P;
}

export interface ISocketAction<P> {
    type: string;
    response_types: string[];
    token: string;
    payload?: P;
}

export type ISocketResponseCreator<P> = (payload?: P) => ISocketResponse<P>;

export interface ISocketResponse<P> {
    type: string;
    payload?: P;
}

export interface ISocketActionArguments {
    type: string;
}

export type ISocketActionCreator<P> = (
    payload?: P
) => ISocketMiddlewareAction<P>;

export interface ICreateSocketAction<P, S, E> {
    action: ISocketActionCreator<P>;

    request: PayloadActionCreator<S, string>;
    update: PayloadActionCreator<S, string>;
    success: PayloadActionCreator<S, string>;
    error: PayloadActionCreator<E, string>;

    type_request: string;
    type_update: string;
    type_success: string;
    type_error: string;
}

/**
 * Generate action creators for socket action with actions for handling
 * REQUEST/UPDATE,SUCCESS/ERROR subsequent actions.
 * @typeParam P - The type of the payload body
 * @typeParam S - The type of the payload the 'success' reducer accepts
 * @typeParam E - The type of the payload the 'error' reducer accepts
 * @typeParam M - The type of the meta object
 * @param args - The action arguments
 * @returns Generated actions for the network call and for handling redux callbacks
 * @public
 */

export const createSocketAction = <P = void, S = void, E = void>(
    args: ISocketActionArguments
): ICreateSocketAction<P, S, E> => {
    const type_request = `${args.type}_REQUEST`;
    const type_update = `${args.type}_UPDATE`;
    const type_success = `${args.type}_SUCCESS`;
    const type_error = `${args.type}_ERROR`;

    return {
        action: (payload?: P): ISocketMiddlewareAction<P> => ({
            type: args.type,
            socket: {
                types: [type_request, type_update, type_success, type_error],
            },
            payload,
        }),

        request: createAction<S>(type_request),
        update: createAction<S>(type_update),
        success: createAction<S>(type_success),
        error: createAction<E>(type_error),

        type_request,
        type_update,
        type_success,
        type_error,
    };
};

export const websocketMiddleware: Middleware =
    (store) => (next) => (action: ISocketMiddlewareAction<any>) => {
        if (action && action.socket) {
            const state: any = store.getState();

            const socketAction: ISocketAction<any> = {
                type: action.type,
                response_types: action.socket.types,
                token: state.user?.socket_token,
                payload: action.payload
                    ? JSON.stringify(action.payload)
                    : undefined,
            };

            send(JSON.stringify(socketAction));
        }

        return next(action);
    };

export const attachWebsocketListener = (store: MiddlewareAPI): void => {
    ws.onmessage = (message) => {
        // Deserialize JSON payload
        if (message.data) {
            const action = JSON.parse(message.data.toString());
            store.dispatch(action);
        }
    };
};

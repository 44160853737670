declare const config;
export const API_BASE_URL = config.api_base_url;
const API_VERSION = "1.0";

export const getPath = (route: string) => `${API_BASE_URL}${route}`;

export const getApiPath = (route: string) =>
    getPath(`/api/${API_VERSION}/${route}`);

const URL_PATTERN = new RegExp(
    [
        // Match params and suffixes
        "(\\/|\\.):(\\w+)(\\?)?",

        // Match automatic greedy matching
        "(\\*)",
    ].join("|"),
    "g"
);

/**
 * Generate a URL from an express route pattern
 * based a the `params` given.
 *
 * @param  {String} path
 * @param  {Object} params
 * @return {String}
 * @api public
 */

export const pathToUrl = (
    path,
    pathParams = undefined,
    queryParams = undefined
) => {
    let url = path.replace(
        URL_PATTERN,
        function (match, prefix, param, optional, greedy) {
            if (greedy) {
                prefix = "";
                optional = true;
                param = "$";
            }
            return !optional || pathParams[param]
                ? prefix + pathParams[param]
                : "";
        }
    );

    if (queryParams) {
        const params = new URLSearchParams(queryParams);
        url = `${url}?${params}`;
    }

    return url;
};

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {
    I18N_DEBUG_MODE,
    I18N_STORE_KEYS_TO_VARIABLE,
} from "../components/utilities/FeatureFlags";
import { setOnWindow } from "../components/utilities/WindowUtils";

const setObjectMap = (
    map: string,
    language: string,
    key: string,
    value: string
) => {
    const theMap = window[map] || {};

    if (!theMap[language]) {
        theMap[language] = {};
    }

    if (!theMap[language][key]) {
        theMap[language][key] = value;
        window[map] = theMap;
    }
};

const setWindowObject = (
    language: string,
    key: string,
    value: string,
    missing: boolean = false
) => {
    if (I18N_STORE_KEYS_TO_VARIABLE()) {
        setObjectMap("i18allKeys", language, key, value);

        if (missing) {
            setObjectMap("i18missingKeys", language, key, value);
        }
    }
};

const LANGUAGES = ["en-US"];
const CONTENT = ["ui", "ghs", "tooltips"];

const resources = {};

LANGUAGES.forEach((language) => {
    const translation = {};
    const translationByContent = {};
    CONTENT.forEach((contentName) => {
        const content = require(
            `./translations/${language}/${contentName}.json`
        );
        Object.assign(translation, content);

        translationByContent[contentName] = {};
        Object.assign(translationByContent[contentName], content);
    });

    resources[language] = { translation };

    if (I18N_STORE_KEYS_TO_VARIABLE()) {
        setOnWindow("i18allKeys", { [language]: { ...translation } });
        setOnWindow("i18byContent", {
            [language]: { ...translationByContent },
        });
    }
});

const missingKeyHandler = (lng, ns, key, fallbackValue) => {
    setWindowObject(lng, key, fallbackValue, true);
};

void i18n.use(LanguageDetector).init(
    {
        debug: I18N_DEBUG_MODE(),
        defaultNS: "translation",
        fallbackLng: "en-US",
        resources,

        interpolation: {
            escapeValue: false, // not needed for react!!
        },

        missingKeyHandler,
        saveMissing: true,
    },
    null
);

const t = i18n.t.bind(i18n);

export { i18n, t };

export default t;

import { createReducer } from "@reduxjs/toolkit";
import { setEmail, setPassword } from "../actions/myAccountPage";
import { logOut } from "../actions/user";

export interface IMyAccountPageState {
    changeEmailError?: string;
    changePasswordError?: string;
}

const getDefaultState = (): IMyAccountPageState => {
    return {
        changeEmailError: undefined,
        changePasswordError: undefined,
    };
};

export const myAccountPage = createReducer(getDefaultState(), (builder) => {
    builder.addCase(setEmail.success, (state, action) => {
        state.changeEmailError = action.payload.error;
    });
    builder.addCase(setEmail.error, (state, action) => {
        state.changeEmailError = action.payload.response.response;
    });
    builder.addCase(setPassword.success, (state, action) => {
        state.changePasswordError = action.payload.error;
    });
    builder.addCase(setPassword.error, (state, action) => {
        state.changePasswordError = action.payload.response.response;
    });
    builder.addCase(logOut.request, () => getDefaultState());
});

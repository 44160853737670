import t from "../../i18n/i18next";
import { closeListItems, openListItems } from "../actions/listitems";

import { createReducer } from "@reduxjs/toolkit";
import { openSynonyms } from "../actions/synonyms";
import { logOut } from "../actions/user";

const getDefaultState = () => {
    return {
        show: false,
        title: "",
        header: "",
        items: [],
    };
};

export const listitems = createReducer(getDefaultState(), (builder) => {
    builder.addCase(openSynonyms.request, (state) => {
        state.show = true;
        state.items = [];
    });
    builder.addCase(openSynonyms.success, (state, action) => {
        state.title = t("Synonyms");
        state.header = "";
        state.items = action.payload;
    });
    builder.addCase(openListItems, (state, action) => {
        state.title = action.payload.title;
        state.header = action.payload.header;
        state.items = action.payload.items;
    });
    builder.addCase(closeListItems, (state, action) => {
        state.show = false;
        state.items = [];
    });
    builder.addCase(logOut.request, (state) => {
        return getDefaultState();
    });
});

import { createReducer } from "@reduxjs/toolkit";

import {
    skipAwsMarketplaceActivation,
    submitAwsMarketplaceActivation,
} from "../actions/awsmarketplace";
import { logOut } from "../actions/user";
import { IUserState } from "../types/IUserState";

export interface IAwsMarketplaceActivation {
    success: boolean;
    message?: string;
    user?: IUserState;
}

export interface IAwsMarketplaceState {
    skippedPendingAwsActivation: boolean;
    errorMessage?: string;
}

const getDefaultState = (): IAwsMarketplaceState => {
    return {
        skippedPendingAwsActivation: false,
    };
};

export const awsMarketplace = createReducer(getDefaultState(), (builder) => {
    builder.addCase(skipAwsMarketplaceActivation, (state) => {
        state.skippedPendingAwsActivation = true;
        state.errorMessage = null;
    });
    builder.addCase(submitAwsMarketplaceActivation.success, (state, action) => {
        if (action.payload.success) {
            state.skippedPendingAwsActivation = true;
        }
        if (!action.payload.success && action.payload.message) {
            state.errorMessage = action.payload.message;
        }
    });
    builder.addCase(logOut.success, (state) => {
        return getDefaultState();
    });
});

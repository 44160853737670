import { createReducer } from "@reduxjs/toolkit";
import { ISearchCallbackData } from "../../components/application/search/SearchBar";
import { IChemicalModel } from "../../data/ChemicalModel";
import {
    estimateCompound,
    getGraphs,
    GraphContextAction,
    loadCompounds,
    loadSavedSearches,
    removeCompound,
    replaceCompounds,
    searchCompounds,
    setGraphContext,
    setPropertiesTab,
} from "../actions/pocketbook";
import { logOut } from "../actions/user";
import { IScoreWeights } from "./persistent/scoreWeights";

export interface IPocketbookReduxState {
    propertiesTab: number;
    graphContext: GraphContextAction;
    allowMultipleResults: boolean;
    compounds: IChemicalModel[];
    searchResults: IChemicalModel[];
    scoreWeights?: IScoreWeights;
}

const getDefaultState = (): IPocketbookReduxState => {
    return {
        propertiesTab: 0,
        graphContext: getGraphs().find((g) => g.default),
        allowMultipleResults: true,
        compounds: [],
        searchResults: [],
    };
};

export interface ISearchCompoundsRequest {
    criteria: ISearchCallbackData;
    allowMultipleResults: boolean;
}

export interface ISearchCompoundsResponse {
    models: IChemicalModel[];
    sort_type: string;
    sender: string;
}

export interface ILoadCompoundsRequest {
    criteria: { compound_ids: number[] };
    clearCompounds: boolean;
    allowMultipleResults: boolean;
    weights?: IScoreWeights;
}

export interface ILoadCompoundsResponse {
    models: IChemicalModel[];
    remaining_queries: number;
    clearCompounds: boolean;
}

export interface IEstimateCompoundsResponse {
    models: IChemicalModel[];
    clearCompounds: boolean;
}

export const pocketbook = createReducer(getDefaultState(), (builder) => {
    builder
        .addCase(logOut.request, () => getDefaultState())
        .addCase(setPropertiesTab, (state, action) => {
            state.propertiesTab = parseInt(action.payload.toString(), 10);
        })
        .addCase(setGraphContext, (state, action) => {
            state.graphContext = action.payload;
        })
        .addCase(loadCompounds.success, (state, action) => {
            const newCompounds: IChemicalModel[] = action.payload.models;
            state.compounds = getCompounds(state.compounds, newCompounds, {
                clearCompounds: action.payload.clearCompounds,
            });
        })
        .addCase(removeCompound, (state, action) => {
            state.compounds.splice(action.payload, 1);
        })
        .addCase(replaceCompounds, (state, action) => {
            state.compounds = action.payload.slice(0);
        })
        .addCase(estimateCompound.request, (state, action) => {
            state.compounds = getCompounds(
                state.compounds,
                action.payload.models,
                { clearCompounds: action.payload.clearCompounds },
                {
                    new_molecule:
                        action.payload.models[0].name.indexOf("CHEM_") >= 0,
                    still_loading: true,
                }
            );
        })
        .addCase(estimateCompound.update, (state, action) => {
            state.compounds = getCompounds(
                state.compounds,
                action.payload.models,
                { clearCompounds: false, onlyUpdateExisting: true }
            );
        })
        .addCase(estimateCompound.success, (state, action) => {
            state.compounds = getCompounds(
                state.compounds,
                action.payload.models,
                { clearCompounds: false, onlyUpdateExisting: true },
                { still_loading: false }
            );
        })
        .addCase(estimateCompound.error, (state, action) => {
            state.compounds = getCompounds(
                state.compounds,
                action.payload.models,
                { clearCompounds: false },
                { still_loading: false }
            );
        })
        .addCase(searchCompounds.success, (state, action) => {
            if (
                action.payload.models.length === 1 ||
                !state.allowMultipleResults
            ) {
                state.compounds = action.payload.models;
            } else {
                state.searchResults = action.payload.models;
            }
        })
        .addCase(loadSavedSearches.success, (state, action) => {
            if (
                action.payload.models.length === 1 ||
                !state.allowMultipleResults
            ) {
                state.compounds = action.payload.models;
            } else {
                state.searchResults = action.payload.models;
            }
        });
});

interface IGetCompoundsOptions {
    clearCompounds?: boolean;
    onlyUpdateExisting?: boolean;
}

interface IGetCompoundsExtraProps {
    still_loading?: boolean;
    new_molecule?: boolean;
}

export const getCompounds = (
    existingCompounds: IChemicalModel[],
    newCompounds: IChemicalModel[],
    options: IGetCompoundsOptions = {},
    additionalProps: IGetCompoundsExtraProps = {}
) => {
    // Only include new compounds if clearCompounds is true
    if (options?.clearCompounds || existingCompounds.length === 0) {
        return newCompounds.map((c) => ({ ...c, ...additionalProps }));
    }

    const firstCompounds = existingCompounds.map((compound) => {
        const updatedCompound = newCompounds
            ? newCompounds.find((c) => compound.guid === c.guid)
            : null;

        // Existing compounds that were updated in the response
        if (updatedCompound) {
            return {
                ...compound,
                ...updatedCompound,
                ...additionalProps,
            };
        } else {
            // Existing compounds that were not updated in the response
            return compound;
        }
    });

    if (options?.onlyUpdateExisting) {
        return firstCompounds;
    }

    const existingGuidSet = new Set(existingCompounds.map((c) => c.guid));

    const onlyNewCompounds = newCompounds
        .filter((c) => !existingGuidSet.has(c.guid))
        .map((c) => ({ ...c, ...additionalProps }));

    return [].concat(firstCompounds, onlyNewCompounds);
};

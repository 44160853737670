import { createAction } from "@reduxjs/toolkit";
import { Action } from "redux";

export const OPEN_LISTITEMS: string = "OPEN_LISTITEMS";
export const CLOSE_LISTITEMS: string = "CLOSE_LISTITEMS";

export interface IListItemsActionPayload {
    title: string;
    header: string;
    items: string[];
}

export interface IListItemsAction extends Action {
    type: string;
    payload: IListItemsActionPayload;
}

export const openListItems =
    createAction<IListItemsActionPayload>(OPEN_LISTITEMS);

export const closeListItems = createAction(CLOSE_LISTITEMS);

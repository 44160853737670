import { getDefaultState as getAdvancedSearchDefaultState } from "../reducers/persistent/advancedsearch";
import { getDefaultState as getSavedStructuresDefaultState } from "../reducers/persistent/savedStructures";
import { getDefaultState as getScoreWeightsDefaultState } from "../reducers/persistent/scoreWeights";

/*
 * Initialize default states for reducers if there is existing
 * persist state
 */

export const migration20230402 = (state) => {
    return {
        ...state,
        advancedsearch: {
            ...state.advancedsearch,
            ...getAdvancedSearchDefaultState(),
        },
        saved_structures: {
            ...state.saved_structures,
            ...getSavedStructuresDefaultState(),
        },
        scoreWeights: {
            ...state.scoreWeights,
            ...getScoreWeightsDefaultState(),
        },
    };
};

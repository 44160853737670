import { createAction } from "@reduxjs/toolkit";

import { createRsaaAction } from "../../components/utilities/ReduxUtils";
import { IApiKey } from "../reducers/apiKeys";

export const API_KEYS_SHOW_MODAL = "API_KEYS_SHOW_MODAL";
export const API_KEYS_HIDE_MODAL = "API_KEYS_HIDE_MODAL";
export const API_KEYS_HIDE_RESULT_MODAL = "API_KEYS_HIDE_RESULT_MODAL";
export const API_KEYS_GET_KEYS = "API_KEYS_GET_KEYS";
export const API_KEYS_CREATE_KEY = "API_KEYS_CREATE_KEY";
export const API_KEYS_DELETE_KEY = "API_KEYS_DELETE_KEY";

export const showApiKeyModal = createAction(API_KEYS_SHOW_MODAL);

export const hideApiKeyModal = createAction(API_KEYS_HIDE_MODAL);

export const hideApiKeyResultModal = createAction(API_KEYS_HIDE_RESULT_MODAL);

export interface IApiKeysResponse {
    keys: IApiKey[];
}

export interface IApiKeysCreateRequest {
    description: string;
}

export interface IApiKeysCreateResponse {
    apiKey: IApiKey;
}

export const getApiKeys = createRsaaAction<void, IApiKeysResponse>({
    path: "users/apiKeys",
    method: "GET",
    type: API_KEYS_GET_KEYS,
});

export const createApiKey = createRsaaAction<
    IApiKeysCreateRequest,
    IApiKeysCreateResponse
>({
    path: "users/apiKeys",
    method: "POST",
    type: API_KEYS_CREATE_KEY,
});

export const deleteApiKey = createRsaaAction<void, IApiKeysResponse>({
    path: "users/apiKeys/:id",
    method: "DELETE",
    type: API_KEYS_DELETE_KEY,
});

import { createMigrate } from "redux-persist";

import { migration20230402 } from "./20230402";

export const createMigrations = createMigrate(
    {
        0: migration20230402,
    },
    { debug: false }
);

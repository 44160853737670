import { createReducer } from "@reduxjs/toolkit";
import { AnyAction } from "redux";

import { SET_CITATIONS_CONTEXT } from "../actions/citations";
import { logOut } from "../actions/user";

const getDefaultState = () => {
    return {
        context: {},
    };
};

export const citations = createReducer(getDefaultState(), (builder) => {
    builder
        .addCase(SET_CITATIONS_CONTEXT, (state, action: AnyAction) => {
            state.context = action.payload;
        })
        .addCase(logOut.request, () => {
            return getDefaultState();
        });
});

import { createAction } from "@reduxjs/toolkit";

import { createRsaaAction } from "../../components/utilities/ReduxUtils";
import { CLOSE_LISTITEMS } from "./listitems";

export const GET_SYNONYMS: string = "GET_SYNONYMS";

export interface ISynonymsRequest {
    compound_id: number;
}

export type ISynonymsResponse = string[];

export const openSynonyms = createRsaaAction<
    ISynonymsRequest,
    ISynonymsResponse
>({
    path: "synonyms",
    method: "POST",
    type: GET_SYNONYMS,
});

export const closeSynonyms = createAction(CLOSE_LISTITEMS);

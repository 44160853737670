import { createReducer } from "@reduxjs/toolkit";
import {
    closeFeedback,
    openFeedback,
    setCompound,
    setFeedback,
    submitFeedback,
} from "../actions/feedbackForm";
import { logOut } from "../actions/user";

export interface IFeedbackFormState {
    show: boolean;
    done: boolean;
    compound_id: number;
    feedback: string;
}

const getDefaultState = (): IFeedbackFormState => {
    return {
        show: false,
        done: false,
        compound_id: undefined,
        feedback: undefined,
    };
};

export const feedbackForm = createReducer(getDefaultState(), (builder) => {
    builder.addCase(openFeedback, (state) => {
        state.show = true;
        state.done = false;
        state.compound_id = undefined;
        state.feedback = null;
    });
    builder.addCase(closeFeedback, () => {
        return getDefaultState();
    });
    builder.addCase(setFeedback, (state, action) => {
        state.feedback = action.payload;
    });
    builder.addCase(setCompound, (state, action) => {
        state.compound_id = action.payload;
    });
    builder.addCase(submitFeedback.success, (state) => {
        state.done = true;
    });
    builder.addCase(logOut.request, () => {
        return getDefaultState();
    });
});

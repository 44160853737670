import { createAction } from "@reduxjs/toolkit";

import { IChemicalModel } from "../../data/ChemicalModel";

export const OPEN_CATEGORIES: string = "OPEN_CATEGORIES";
export const CLOSE_CATEGORIES: string = "CLOSE_CATEGORIES";

export const openCategories = createAction<IChemicalModel>(OPEN_CATEGORIES);

export const closeCategories = createAction(CLOSE_CATEGORIES);

import { createAction } from "@reduxjs/toolkit";
import { IApiSelectOption } from "../../components/application/advancedsearch/AdvancedSearchElement";
import { createRsaaAction } from "../../components/utilities/ReduxUtils";
import { ILicense, IOrganization } from "../reducers/viridischemadmin";

export const ADMIN_SET_SHOW_ORGANIZATION_MODAL =
    "ADMIN_SET_SHOW_ORGANIZATION_MODAL";
export const ADMIN_GET_FEATURES = "ADMIN_GET_FEATURES";
export const ADMIN_GET_LICENSES = "ADMIN_GET_LICENSES";
export const ADMIN_GET_ORGANIZATIONS = "ADMIN_GET_ORGANIZATIONS";
export const ADMIN_GET_ORGANIZATION_FEATURES =
    "ADMIN_GET_ORGANIZATION_FEATURES";
export const ADMIN_SET_ORGANIZATION_PROPERTY =
    "ADMIN_SET_ORGANIZATION_PROPERTY";
export const ADMIN_CLEAR_ORGANIZATION = "ADMIN_CLEAR_ORGANIZATION";
export const ADMIN_SET_ORGANIZATION_FEATURE = "ADMIN_SET_ORGANIZATION_FEATURE";
export const ADMIN_SAVE_ORGANIZATION = "ADMIN_SAVE_ORGANIZATION";
export const ADMIN_CREATE_ACCOUNT = "ADMIN_CREATE_ACCOUNT";
export const ADMIN_CLOSE_CREATE_ACCOUNT_RESULT =
    "ADMIN_CLOSE_CREATE_ACCOUNT_RESULT";

export interface IGetOrganizationsResponse {
    organizations: IOrganization[];
    pages: number;
}

export const getFeatures = createRsaaAction<void, IApiSelectOption[]>({
    path: "admin/features",
    method: "GET",
    type: ADMIN_GET_FEATURES,
});

export const getLicenses = createRsaaAction<void, ILicense[]>({
    path: "admin/licenses",
    method: "GET",
    type: ADMIN_GET_LICENSES,
});

export const getOrganizations = createRsaaAction<
    void,
    IGetOrganizationsResponse
>({
    path: "admin/organizations",
    method: "GET",
    type: ADMIN_GET_ORGANIZATIONS,
});

export interface IOrganizationsFeaturesResponse {
    organization_id: number;
    features: string[];
    license_features: string[];
}

export const getOrganizationFeatures = createRsaaAction<
    void,
    IOrganizationsFeaturesResponse
>({
    path: "admin/features/:organizationId",
    method: "GET",
    type: ADMIN_GET_ORGANIZATION_FEATURES,
});

export interface ISetOrganizationPropertyPayload {
    organization: IOrganization;
}

export const setOrganizationProperty =
    createAction<ISetOrganizationPropertyPayload>(
        ADMIN_SET_ORGANIZATION_PROPERTY
    );

export const clearOrganization = createAction<number>(ADMIN_CLEAR_ORGANIZATION);

export interface ISetOrganizationFeaturePayload {
    organization_id: number;
    feature: string;
    enabled: boolean;
}

export const setOrganizationFeature =
    createAction<ISetOrganizationFeaturePayload>(
        ADMIN_SET_ORGANIZATION_FEATURE
    );

export interface ISaveOrganizationPayload {
    organization: IOrganization;
    features: string[];
}

export const saveOrganization = createRsaaAction<
    ISaveOrganizationPayload,
    IOrganization
>({
    path: "admin/organizations",
    method: "PATCH",
    type: ADMIN_SAVE_ORGANIZATION,
});

export interface ICreateAccountRequest {
    email: string;
    first_name: string;
    last_name: string;
    company: string;
    license: string;
    queries: number;
    days: number;
    features?: string[];
}

export interface ICreateAccountResponse {
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    company: string;
    license: string;
    queries: number;
    inheritedMaxQueries: boolean;
    endDate: string;
    errorMessage: string;
    features: string[];
}

export const createAccount = createRsaaAction<
    ICreateAccountRequest,
    ICreateAccountResponse
>({
    path: "admin/createAccount",
    method: "POST",
    type: ADMIN_CREATE_ACCOUNT,
});

export const closeCreateAccountResult = createAction(
    ADMIN_CLOSE_CREATE_ACCOUNT_RESULT
);

import { createReducer } from "@reduxjs/toolkit";
import { estimateCompound, loadCompounds } from "../actions/pocketbook";
import { LOADING_START, LOADING_STOP } from "../actions/searchbar";

export interface ISearchBarState {
    loading: boolean;
}

const getDefaultState = (): ISearchBarState => ({ loading: false });

export const searchbar = createReducer(getDefaultState(), (builder) => {
    builder
        .addCase(LOADING_START, (state) => {
            state.loading = true;
        })
        .addCase(loadCompounds.request, (state) => {
            state.loading = true;
        })
        .addCase(LOADING_STOP, (state) => {
            state.loading = false;
        })
        .addCase(loadCompounds.success, (state) => {
            state.loading = false;
        })
        .addCase(loadCompounds.error, (state) => {
            state.loading = false;
        })
        .addCase(estimateCompound.request, (state) => {
            state.loading = false;
        });
});

import { createRsaaAction } from "../../components/utilities/ReduxUtils";

export const GET_USER_LICENSE: string = "GET_USER_LICENSE";
export const USER_LOGIN: string = "USER_LOGIN";
export const USER_LOGOUT: string = "USER_LOGOUT";

export interface IUserLoginRequest {
    email: string;
    password?: string;
    force?: boolean;
}

export const logIn = createRsaaAction<IUserLoginRequest>({
    path: "login",
    method: "POST",
    type: USER_LOGIN,
});

export const logOut = createRsaaAction({
    path: "logout",
    method: "POST",
    type: USER_LOGOUT,
});

export interface IUserLicenseInfoResponse {
    units: number;
    units_consumed: number;
    units_remaining: number;
}

export const getUserLicenseInfo = createRsaaAction<
    void,
    IUserLicenseInfoResponse
>({
    path: "users/license",
    method: "GET",
    type: GET_USER_LICENSE,
});

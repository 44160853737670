import { createRsaaAction } from "../../components/utilities/ReduxUtils";
import { IChemicalModel } from "../../data/ChemicalModel";
import { createSocketAction } from "../middlewares/websocket";
import { IEstimateCompoundsResponse } from "../reducers/pocketbook";
import { IEstimatePayload } from "./pocketbook";

export const CHEMPREDICTOR_PREDICT: string = "CHEMPREDICTOR_PREDICT";
export const CHEMPREDICTOR_SOCKET_PREDICT: string =
    "CHEMPREDICTOR_SOCKET_PREDICT";

export interface IPredictStructureRequest {
    smiles: string;
}

export interface IPredictStructureResponse {
    chemical: IChemicalModel;
}

export const predictStructure = createRsaaAction<
    IPredictStructureRequest,
    IPredictStructureResponse
>({
    path: "chempredictor/predict",
    method: "POST",
    type: CHEMPREDICTOR_PREDICT,
});

export const predictStructureSocket = createSocketAction<
    IEstimatePayload,
    IEstimateCompoundsResponse,
    IEstimateCompoundsResponse
>({
    type: CHEMPREDICTOR_SOCKET_PREDICT,
});

import { RSAA } from "redux-api-middleware";

import { store } from "../store";

export const API_CACHE_SEND: string = "API_CACHE_SEND";
export const API_CACHE_SEND_REQUEST: string = "API_CACHE_SEND_REQUEST";
export const API_CACHE_SEND_SUCCESS: string = "API_CACHE_SEND_SUCCESS";
export const API_CACHE_SEND_ERROR: string = "API_CACHE_SEND_ERROR";

export interface ICacheRequestInit {
    context: string;
    url: string;
    key?: string;
    body?: any;
}

export interface ICacheRequest extends ICacheRequestInit {
    resolve?: (payload: any) => void;
    reject?: (err: any) => void;
    promise?: Promise<any>;
}

export interface ICacheResponse {
    read: () => void;
    promise: Promise<any>;
}

interface IApiCacheMap {
    [context: string]: {
        [name: string]: ICacheRequest;
    };
}

export const promiseMap: IApiCacheMap = {};

/**
 * Get a cache request resource that can be used in a React Suspense component
 * @param request
 */
export const getCacheRequestApiResource = (
    request: ICacheRequest
): ICacheResponse => {
    let status = "loading";
    let result;

    const cachePromise = cacheRequest(request);
    const suspender = cachePromise.then(
        (data) => {
            status = "success";
            result = data;
        },
        (error) => {
            status = "error";
            result = error;
        }
    );

    return {
        read: (): string => {
            if (status === "loading") {
                throw suspender;
            } else if (status === "error") {
                throw result;
            } else if (status === "success") {
                return result;
            }
        },
        promise: cachePromise,
    };
};

export const cacheRequest = (request: ICacheRequest): Promise<any> => {
    // Autogenerate a key based on request parameters
    // if needed

    if (!request.key && request.body) {
        request.key = JSON.stringify(request.body);
    }

    // Cache hit, return the promise
    if (
        promiseMap[request.context] &&
        promiseMap[request.context][request.key]
    ) {
        return promiseMap[request.context][request.key].promise;
    }

    // Cache miss, pass the promise in the action and let it resolve there
    const action = cacheRequestAction(request);
    request.promise = new Promise((resolve, reject) => {
        request.resolve = resolve;
        request.reject = reject;
    });

    if (!promiseMap[request.context]) {
        promiseMap[request.context] = {};
    }

    promiseMap[request.context][request.key] = request;
    store.dispatch(action);
    return request.promise;
};

export const cacheRequestAction = (request: ICacheRequestInit): any => {
    return {
        [RSAA]: {
            endpoint: request.url,
            method: "POST",
            body: request.body,
            types: [
                API_CACHE_SEND_REQUEST,
                API_CACHE_SEND_SUCCESS,
                API_CACHE_SEND_ERROR,
            ],
        },
        meta: { context: request.context, key: request.key },
    };
};

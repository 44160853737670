export const LOADING_START = "LOADING_START";
export const LOADING_STOP = "LOADING_STOP";

export const setIsLoading = (isLoading: boolean) => ({
    type: isLoading ? LOADING_START : LOADING_STOP,
});

export const startLoading = () => {
    return setIsLoading(true);
};
export const stopLoading = () => {
    return setIsLoading(false);
};

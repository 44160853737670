import { alert } from "../../components/global/alert/Alert";
// tslint:disable-next-line:no-submodule-imports
import { isString } from "lodash";
import { all, takeEvery } from "redux-saga/effects";
import t from "../../i18n/i18next";
import { API_CALL_ERROR, IApiCallError } from "../fetch";

function* alertError(action: IApiCallError) {
    const errorMessage = action.payload.response;
    if (isString(errorMessage)) {
        alert(t(errorMessage));
    }
    yield;
}

function* apiSaga() {
    yield all([takeEvery(API_CALL_ERROR, alertError)]);
}

export default apiSaga;

import swal from "sweetalert";

export enum AlertIcon {
    WARNING,
    ERROR,
    SUCCESS,
    INFO,
}

export function alert(
    text: string,
    title?: string,
    iconValue?: AlertIcon,
    callback?: () => void
) {
    let icon: string;

    if (iconValue !== undefined) {
        switch (+iconValue) {
            case AlertIcon.WARNING:
                icon = "warning";
                break;
            case AlertIcon.ERROR:
                icon = "error";
                break;
            case AlertIcon.SUCCESS:
                icon = "success";
                break;
            case AlertIcon.INFO:
                icon = "info";
                break;
        }
    }

    let buttons: any;
    if (callback) {
        buttons = {
            ok: "OK",
            cancel: "Cancel",
        };
    }

    void swal({
        title,
        text,
        icon,
        buttons,
    }).then((value) => {
        if (value === "ok") {
            callback();
        }
    });
}

import { createBrowserHistory } from "history";
import { forwardRef } from "react";
import { combineReducers } from "redux";
import { apiMiddleware } from "redux-api-middleware";
import { createReduxHistoryContext, push } from "redux-first-history";
import {
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,
    persistReducer,
    persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import { configureStore } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { attachReduxStore } from "../components/utilities/FeatureFlags";
import {
    cacheMiddleware,
    rsaaExtenderMiddleware,
} from "../redux/middlewares/rsaaExtender";
import {
    attachWebsocketListener,
    websocketMiddleware,
} from "../redux/middlewares/websocket";
import { gaMiddleware } from "./middlewares/analytics";
import { createMigrations } from "./migrations";
import rootReducer from "./reducers";
import rootSaga from "./sagas";

const browserHistory = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["persistent"],
    migrate: createMigrations,
};

const { createReduxHistory, routerMiddleware, routerReducer } =
    createReduxHistoryContext({
        history: browserHistory,
        // other options if needed
    });

const combinedReducer = combineReducers({
    router: routerReducer,
    ...rootReducer,
});

export const store = configureStore({
    reducer: persistReducer(persistConfig, combinedReducer),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }).prepend(
            routerMiddleware,
            gaMiddleware,
            rsaaExtenderMiddleware,
            apiMiddleware,
            cacheMiddleware,
            websocketMiddleware,
            sagaMiddleware
        ),
});

attachWebsocketListener(store);
attachReduxStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

sagaMiddleware.run(rootSaga);

export const history = createReduxHistory(store);

export const historyPush = push;

export const persistor = persistStore(store);

export const connectAndForwardRef =
    (
        mapStateToProps = null,
        mapDispatchToProps = null,
        mergeProps = null,
        options = {}
    ) =>
    (component) =>
        connect(mapStateToProps, mapDispatchToProps, mergeProps, {
            ...options,
            forwardRef: true,
        })(forwardRef(component));

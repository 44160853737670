import GoogleAnalyticsGtag, {
    trackEvent,
} from "@redux-beacon/google-analytics-gtag";
import { UnknownAction } from "redux";
import { RSAA } from "redux-api-middleware";
import { createMiddleware } from "redux-beacon";
import {
    API_CALL_EXCEPTION,
    API_CALL_TIMING,
    IApiCallError,
    IApiCallTiming,
} from "../fetch";

// Create or import an events map.
// See "getting started" pages for instructions.
const eventsMap = {
    [API_CALL_TIMING]: trackEvent((action: IApiCallTiming) => ({
        action: "timing_complete",
        category: action.type,
        name: action.url,
        value: action.time,
    })),
    [API_CALL_EXCEPTION]: trackEvent((action: IApiCallError) => ({
        action: "exception",
        category: action.type,
        description: action.description,
        fatal: false,
    })),
    "*": (action: UnknownAction, prevState, nextState) => {
        const isAjax: boolean = action[RSAA] !== undefined;
        const isSocket: boolean = action.socket !== undefined;

        if (isAjax) {
            return trackEvent((action) => ({
                action: "event",
                category: "ajax",
                label: action.type,
            }))(action, prevState, nextState);
        }

        if (isSocket) {
            return trackEvent((action) => ({
                action: "event",
                category: "socket",
                label: action.type,
            }))(action, prevState, nextState);
        }

        if (action.meta || action.responseType) {
            return trackEvent((action) => ({
                action: "event",
                category: "response",
                label: action.type,
            }))(action, prevState, nextState);
        }
    },
};

const trackingId = "G-SCH01PXEP9";
const ga = GoogleAnalyticsGtag(trackingId);

export const gaMiddleware = createMiddleware(eventsMap, ga);

import { createReducer } from "@reduxjs/toolkit";

import { IChemicalModel } from "../../data/ChemicalModel";
import {
    predictStructure,
    predictStructureSocket,
} from "../actions/chempredictor";
import { logOut } from "../actions/user";
import { getCompounds } from "./pocketbook";

export interface IChemPredictorReduxState {
    loading: boolean;
    compounds: IChemicalModel[];
}

const getDefaultState = (): IChemPredictorReduxState => {
    return {
        loading: false,
        compounds: [],
    };
};

export const chempredictor = createReducer(getDefaultState(), (builder) => {
    builder
        .addCase(logOut.request, () => {
            return getDefaultState();
        })
        .addCase(predictStructure.request, (state) => {
            state.loading = true;
        })
        .addCase(predictStructure.success, (state, action) => {
            state.loading = false;
            state.compounds = getCompounds(
                state.compounds,
                [action.payload.chemical],
                { clearCompounds: true },
                { new_molecule: true }
            );
        })
        .addCase(predictStructure.error, (state, action) => {
            state.loading = false;
        })
        .addCase(predictStructureSocket.request, (state, action) => {
            state.loading = true;
            state.compounds = getCompounds(
                state.compounds,
                action.payload.models,
                { clearCompounds: action.payload.clearCompounds },
                { new_molecule: true, still_loading: true }
            );
        })
        .addCase(predictStructureSocket.update, (state, action) => {
            state.compounds = getCompounds(
                state.compounds,
                action.payload.models,
                { clearCompounds: false, onlyUpdateExisting: true }
            );
        })
        .addCase(predictStructureSocket.success, (state, action) => {
            state.loading = false;
            state.compounds = getCompounds(
                state.compounds,
                action.payload.models,
                { clearCompounds: false, onlyUpdateExisting: true },
                { still_loading: false }
            );
        })
        .addCase(predictStructureSocket.error, (state, action) => {
            state.loading = false;
            state.compounds = getCompounds(
                state.compounds,
                action.payload.models,
                { clearCompounds: false },
                { still_loading: false }
            );
        });
});
